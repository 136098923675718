
import { defineComponent, onMounted, ref } from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import FileImage from '@/components/icons/FileImage.vue';
import MailIcon from '@/views/layouts/AppLayout/icons/MailIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'MemberDetail',
  components: {
    Container,
    IconBase,
    CheckCircleIcon,
    FileImage,
    MailIcon,
    ArrowLeftIcon
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const memberDetail = ref<any>(null);
    const memberIdParam = ref<number>();

    onMounted(async () => {
        // 저장된 멤버정보를 가져온다.
      await fetchMemberInfo(memberIdParam.value);

    });

    const fetchMemberInfo = async (memberId) => {
      try {
        const { data } = await partnerAPI.partnerMember.partnerMember({
          memberId,
        });
        memberDetail.value = (data as any).data;

      } catch (e) {
        router.go(-1);
        alert(getServerErrorMessage(e));
      }
    };

    const resetPassword = async (memberId) => {
      try {
        const { data } = await partnerAPI.partnerMember.partnerMemberResetPassword({
          memberId,
        });
        alert(data.message)
        console.log('리셋하기', data.message);
      } catch (error) {
        console.error(error);
        alert(error)
      }
    }

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    const gotoEdit = () => {
      router.push({
        path: '/app/member/edit/'
      })
    }

    return {
      memberIdParam,
      memberDetail,
      gotoEdit,
      resetPassword,
      fetchMemberInfo,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
    };
  },
});
