<template>
  <Container v-if="memberDetail" :style="{ padding: 0 }">
    <div
      class="h-15 px-6 bg-black flex justify-between text-white items-center"
    >
      <div class="text-xl flex items-center font-bold">MEMBER# {{ memberDetail.member.memberId }}
      </div>
      <div v-if="memberDetail.memberType === 'MEMBER'" class="text-sm">Created : {{ formatDate(memberDetail.member.createdAt) || '-' }} | Updated : {{ formatDate(memberDetail.member.updatedAt) || '-' }}</div>
      <div v-else class="text-sm">Created : {{ formatDate(memberDetail.partner.createdAt) || '-' }} | Updated : {{ formatDate(memberDetail.partner.updatedAt) || '-' }}</div>
    </div>
    <div class="pt-14 pb-10 px-10 mb-10 relative">
      <div>
        <div class="font-bold text-xl">기본 정보</div>
        <div class="mt-2 text-sm">
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">회원명</div>
              <div>
                {{ memberDetail.memberType === 'MEMBER' ? memberDetail.member.name : memberDetail.partner.companyName }}
              </div>
            </div>
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">회원구분</div>
              <div>{{ memberDetail.memberType === 'MEMBER' ? '일반회원' : memberDetail.partner.approved ? '파트너회원' : '파트너(승인대기)' }}
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">회원아이디(이메일)</div>
              <div>{{ memberDetail.memberType === 'MEMBER' ? memberDetail.member.email : memberDetail.partner.csEmail }}</div>
            </div>
            <div class="flex items-center">
              <div class="InfoTable__labelColumn">비밀번호</div>
              <div>
                <button type="button" class="px-3 inline-flex items-center rounded-sm py-1 bg-black text-sm text-white" @click="resetPassword(memberDetail.member.memberId)">
                  <IconBase class="mr-1">
                    <MailIcon></MailIcon>
                  </IconBase>
                  비밀번호 재설정
                </button>
              </div>
            </div>
          </div>
          <div v-if="memberDetail.memberType === 'MEMBER'" class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">휴대폰번호</div>
              <div>{{ memberDetail.member.mobile || '-' }}</div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">생년월일</div>
              <div>{{ memberDetail.member.birthday || '-' }}</div>
            </div>
          </div>
          <div v-if="memberDetail.memberType === 'MEMBER'" class="ProductInfoTable__row with-border">
            <div class="flex">
              <div class="InfoTable__labelColumn">기본주소</div>
              <div>
                {{ memberDetail.member.defaultShippingAddress && (memberDetail.member.defaultShippingAddress.address1 + ' ' + memberDetail.member.defaultShippingAddress.address2) || '-' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="memberDetail.memberType === 'PARTNER'" class="mt-10">
        <div class="font-bold text-xl">사업자정보</div>
        <div class="mt-2 text-sm">
          <div class="ProductInfoTable__row">
            <div class="grid grid-cols-3">
              <div class="flex">
                <div class="InfoTable__labelColumn">사업자등록번호</div>
                <div>{{ memberDetail.partner.businessNumber || '-' }}</div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">상호</div>
                <div>{{ memberDetail.partner.companyName || '-' }}</div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">사업자등록증</div>
                <div>
                  <a v-if="memberDetail.partner.businessLicense" :href="memberDetail.partner.businessLicense" class="download-link member-link inline-flex" target="_blank">
                    <IconBase>
                      <FileImage></FileImage>
                    </IconBase>
                    <span class="ml-1">사업자등록증</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">업태</div>
              <div>{{ memberDetail.partner.industry  || '-' }}</div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">종목</div>
              <div>{{ memberDetail.partner.sector  || '-' }}</div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">대표자 이름</div>
              <div>{{ memberDetail.partner.ceoName  || '-' }}</div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">대표자 휴대폰번호</div>
              <div>{{ memberDetail.partner.ceoMobile  || '-' }}</div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">대표 이메일</div>
              <div>{{ memberDetail.partner.csEmail || '-' }}</div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">통신판매업신고번호</div>
              <div>{{ memberDetail.partner.onlineSalesBusinessNo || '-' }}</div>
            </div>
          </div>
          <div class="ProductInfoTable__row">
            <div class="flex">
              <div class="InfoTable__labelColumn">주소</div>
              <div>{{ (memberDetail.partner.address1 + '' + memberDetail.partner.address2) || '-' }}</div>
            </div>
          </div>
          <div class="ProductInfoTable__row">
            <div class="grid grid-cols-3">
              <div class="flex">
                <div class="InfoTable__labelColumn">운영담당자</div>
                <div>{{ memberDetail.partner.managerName || '-' }}</div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">운영담당자 휴대폰번호</div>
                <div>{{ memberDetail.partner.managerMobile  || '-'}}</div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">운영담당자 이메일</div>
                <div>{{ memberDetail.partner.managerEmail || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="ProductInfoTable__row">
            <div class="grid grid-cols-3">
              <div class="flex">
                <div class="InfoTable__labelColumn">정산담당자</div>
                <div>{{ memberDetail.partner.paymentManagerName || '-' }}</div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">정산담당자 휴대폰번호</div>
                <div>{{ memberDetail.partner.paymentManagerMobile || '-' }}</div>
              </div>
              <div class="flex">
                <div class="InfoTable__labelColumn">정산담당자 이메일</div>
                <div>{{ memberDetail.partner.paymentManagerEmail || '-' }}</div>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 ProductInfoTable__row with-border">
            <div class="flex">
              <div class="InfoTable__labelColumn">입금계좌</div>
              <div>{{memberDetail.partner.bankName || '-' }} {{memberDetail.partner.bankAccount || '-' }} (예금주: {{ memberDetail.partner.accountHolder || '-' }})</div>
            </div>
            <div class="flex">
              <div class="InfoTable__labelColumn">통장사본</div>
              <div>
                <a v-if="memberDetail.partner.bankbookCopy" :href="memberDetail.partner.bankbookCopy" class="download-link member-link inline-flex" target="_blank">
                  <IconBase>
                    <FileImage></FileImage>
                  </IconBase>
                  <span class="ml-1">통장사본</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="cols mr-10">
            <div class="flex items-center mt-10">
              <h2 class="text-sm ">셀러홈 배너 이미지</h2>
              <p class="ml-4 text-xs text-gray-500">(1600 * 400 사이즈)</p>
            </div>
            <div class="mt-2">
              <label
                class="
                  border
                  flex
                  items-center
                  justify-center
                  drag-container
                  relative
                "
                style="width: 800px; height: 200px;"
                :for="`sellerImg1`"
              >
                <span
                  class="w-full	h-full bg-cover"
                  :style="{
                    backgroundImage: `url(${memberDetail.partner.homeBannerImage ? memberDetail.partner.homeBannerImage : '/images/seller-bg.jpeg'})`,
                  }"></span>
              </label>
            </div>
          </div>
          <div>
            <div class="flex items-center mt-10">
              <h2 class="text-sm ">프로필 사진</h2>
              <p class="ml-4 text-xs text-gray-500">(100 * 100 사이즈) 이상</p>
            </div>
            <div class="mt-2">
              <label
                class="
                  border
                  flex
                  items-center
                  justify-center
                  drag-container
                  relative
                  rounded-full
                "
                style="width: 200px; height: 200px;"
                :for="`sellerImg2`"
              >
                <span
                  class="w-full	h-full bg-cover rounded-full"
                  :style="{
                    backgroundImage: `url(${memberDetail.partner.profileImage ? memberDetail.partner.profileImage : '/images/default-img-blue.png'})`,
                  }"></span>
              </label>
            </div>
          </div>
        </div>

      </div>
      <div class="flex items-center justify-center">
        <button
            @click="gotoEdit"
            class="
              w-60
              h-16
              border border-black
              shadow
              flex
              items-center
              justify-center
              mt-10
              mx-auto
              admin-button
            "
        >
          <IconBase>
            <CheckCircleIcon></CheckCircleIcon>
          </IconBase>
          <span class="ml-2">회원정보 수정</span>
        </button>
      </div>
      <router-link to="/app/member" class="mt-4 member-list__link flex items-center">
        <IconBase :width="24" :height="24" class="mr-1">
          <ArrowLeftIcon></ArrowLeftIcon>
        </IconBase>
        목록으로
      </router-link>
    </div>
  </Container>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import convertNumWithComma from '@/utils/convertNumWithComma';
import Container from '@/components/Container.vue';
import router from '@/router/index';
import scrollToTopOnMountMixin from '@/mixins/scrollToTopOnMountMixin';
import partnerAPI from '@/service/partnerAPI';
import format from 'date-fns/format';
import { useRoute } from 'vue-router';
import IconBase from '@/components/icons/IconBase.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import FileImage from '@/components/icons/FileImage.vue';
import MailIcon from '@/views/layouts/AppLayout/icons/MailIcon.vue';
import getServerErrorMessage from '@/utils/getServerErrorMessage';

export default defineComponent({
  name: 'MemberDetail',
  components: {
    Container,
    IconBase,
    CheckCircleIcon,
    FileImage,
    MailIcon,
    ArrowLeftIcon
  },
  mixins: [scrollToTopOnMountMixin],
  props: {},
  setup() {
    const memberDetail = ref<any>(null);
    const memberIdParam = ref<number>();

    onMounted(async () => {
        // 저장된 멤버정보를 가져온다.
      await fetchMemberInfo(memberIdParam.value);

    });

    const fetchMemberInfo = async (memberId) => {
      try {
        const { data } = await partnerAPI.partnerMember.partnerMember({
          memberId,
        });
        memberDetail.value = (data as any).data;

      } catch (e) {
        router.go(-1);
        alert(getServerErrorMessage(e));
      }
    };

    const resetPassword = async (memberId) => {
      try {
        const { data } = await partnerAPI.partnerMember.partnerMemberResetPassword({
          memberId,
        });
        alert(data.message)
        console.log('리셋하기', data.message);
      } catch (error) {
        console.error(error);
        alert(error)
      }
    }

    const formatDate = (date: string) => {
      return formatDatePattern(date, 'yyyy/MM/dd HH:mm');
    };

    const formatDatePattern = (date: string, pattern) => {
      return format(new Date(date), pattern);
    };

    const gotoEdit = () => {
      router.push({
        path: '/app/member/edit/'
      })
    }

    return {
      memberIdParam,
      memberDetail,
      gotoEdit,
      resetPassword,
      fetchMemberInfo,
      formatDate,
      formatDatePattern,
      convertNumWithComma,
    };
  },
});
</script>

<style lang="scss" scoped>
.ProductInfoTable__row {
  border-bottom: 1px solid #ddd;
  padding: 0.65rem 0;
  &:last-child {
    border-bottom: none;
  }
  &.with-border {
    border-bottom: 1px solid #ddd;
  }
  &.with-button {
    padding: 5px 0;
  }
}

.InfoTable__labelColumn {
  color: #999;
  width: 144px;
  flex-shrink: 0;
}
.member-list__link {
  position: absolute;
  bottom: -60px;
  left: 0;
}
@import "../../../assets/style/label";
</style>
